<template>
	<div class="wrapper page-lives">
		<div class="match">
			<div class="match-tabs">
				<router-link :to="`/lives/1`" :class="{ active: query.type == 1 }">{{ $t('allLive') }}</router-link>
				<a-popover placement="bottom">
					<template slot="content">
						<ul class="subtabs" v-if="Array.isArray(subtabs[2])">
							<li v-for="item in subtabs[2]" :key="item.id">
								<router-link
									:to="`/lives/2?league_id=${item.id}`"
								>
									{{ item.text }}
									<img :src="item.icon" alt="">
								</router-link>
							</li>
						</ul>
					</template>
					<router-link to="/lives/2" :class="{ active: query.type == 2 }">
              {{ $t('football') }}
						<i></i>
					</router-link>
				</a-popover>

				<a-popover placement="bottom">
					<template slot="content">
						<ul class="subtabs" v-if="Array.isArray(subtabs[3])">
							<li v-for="item in subtabs[3]" :key="item.id">
								<router-link
									:to="`/lives/3?league_id=${item.id}`"
								>
									{{ item.text }}
									<img :src="item.icon" alt="">
								</router-link>
							</li>
						</ul>
					</template>
					<router-link to="/lives/3" :class="{ active: query.type == 3 }">
              {{ $t('basketball') }}
						<i></i>
					</router-link>
				</a-popover>
				<router-link to="/lives/99" :class="{ active: query.type > 3 }">{{ $t('comprehensive') }}</router-link>
				<!-- <a-popover placement="bottom">
					<template slot="content">
						<ul class="subtabs" v-if="Array.isArray(subtabs[99])">
							<li v-for="item in subtabs[99]" :key="item.id">
								<router-link
									:to="`/lives/${item.id}`"
									:style="{
										backgroundImage: `url(${item.icon})`
									}"
								>
									{{ item.text }}
								</router-link>
							</li>
						</ul>
					</template>
					<router-link to="/lives/99" :class="{ active: query.type > 3 }">
						综合
						<i></i>
					</router-link>
				</a-popover> -->
			</div>
			<div class="match-body" v-if="lists.length > 0"><ws-match-item v-for="(item, index) in lists" :key="index" :item="item" /></div>
			<div class="match-empty" v-else></div>
		</div>
	</div>
</template>

<script>
import wsMatchItem from '@/components/wsMatchItem';
export default {
	name: 'Home',
	components: {
		wsMatchItem
	},
	data() {
		return {
			query: {
				type: 1
			},
      subtabs: {
            2: [
                {
                    id: 36,
                    text: this.$t('PremierLeague'),
                    icon: require('@/assets/img/icon-1-36.png')
                },
                {
                    id: 31,
                    text: this.$t('LaLiga'),
                    icon: require('@/assets/img/icon-1-31.png')
                },
                {
                    id: 34,
                    text: this.$t('SerieA'),
                    icon: require('@/assets/img/icon-1-34.png')
                },
                {
                    id: 8,
                    text: this.$t('Bundesliga'),
                    icon: require('@/assets/img/icon-1-8.png')
                },
                {
                    id: 11,
                    text: this.$t('FrenchLeague'),
                    icon: require('@/assets/img/icon-1-11.png')
                },
                {
                    id: 60,
                    text: this.$t('ChineseSuperLeague'),
                    icon: require('@/assets/img/icon-1-60.png')
                },
                {
                    id: 103,
                    text: this.$t('ChampionsLeague'),
                    icon: require('@/assets/img/icon-1-103.png')
                },
                {
                    id: 192,
                    text: this.$t('AFCChampionsLeagueCup'),
                    icon: require('@/assets/img/icon-1-192.png')
                }
            ],
            3: [
                {
                    id: 1,
                    text: 'NBA',
                    icon: require('@/assets/img/icon-2-1.png')
                },
                {
                    id: 5,
                    text: 'CBA',
                    icon: require('@/assets/img/icon-2-5.png')
                }
            ],
            99: [
                {
                    id: 9,
                    text: this.$t('e_sports'),
                    icon: require('@/assets/img/icon-3-9.png')
                },
                {
                    id: 5,
                    text: this.$t('tennis'),
                    icon: require('@/assets/img/icon-3-5.png')
                },
                {
                    id: 6,
                    text: this.$t('volleyball'),
                    icon: require('@/assets/img/icon-3-6.png')
                },
                {
                    id: 8,
                    text: this.$t('badminton'),
                    icon: require('@/assets/img/icon-3-8.png')
                },
                {
                    id: 7,
                    text: this.$t('billiards'),
                    icon: require('@/assets/img/icon-3-7.png')
                },
                {
                    id: 4,
                    text: this.$t('rest'),
                    icon: require('@/assets/img/icon-3-4.png')
                }
            ]
        },

			lists: []
		};
	},
	created() {
		const to = this.$route;
		Object.assign(this.query, to.params, to.query);
		this.$loading();
		this.getLives();
	},
	beforeRouteUpdate(to, from, next) {
		this.query = Object.assign({}, to.params, to.query);
		console.info('to', to.query);
		this.getLives();
		next();
	},
	methods: {
		async getLives() {
			const { type, league_id } = this.query;
			// console.info()
			const response = await this.$api.get(`/web/live_lists/${type}`, {
				params: { league_id }
			});
			if (response) {
				this.lists = response.data.data.map(this.makeMatchItem);
			}
			this.$nextTick(() => {
				this.$loadend();
			});
		}
	}
};
</script>

<style lang="less">
// @import url("~@/assets/less/live.less");
</style>
